import React, { useState, useEffect , useRef } from "react";
import { useParams, useHistory } from 'react-router-dom'
import { url } from '../actions/config'
import axios from 'axios'
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from 'react-quill';
import { BiArrowBack } from "react-icons/bi";
import 'react-quill/dist/quill.snow.css';


export default function AddCertificate() {

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [courses, setCourses] = useState([])
  const [selectedCourse, setSelectedCourse] = useState({})
  const [title, setTitle] = useState('')
  const [number, setNumber] = useState(undefined)
  const [content, setContent] = useState('')
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  const { id } = useParams()
  const history = useHistory()
  const editorRef = useRef(null);


  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold','italic'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image' ], //removed 'video'
      [
        {
          color: ["red", "blue", "yellow" , "green" , "black" , "pink" , "brown"]
        }
      ],
      ['clean']
    ]
  };

  const formats = [
    'header', 'font', 'size',
    'bold','list', 'italic','bullet', 'indent',
    'link', 'image','color'
  ];


  const getCourses = () => {
    axios.post(url() + 'api/admin/courses/list').then(response => {
      if (response?.status == 200) {
        const courses = response?.data?.data || []
        setCourses(courses)
        setSelectedCourse(courses[0]?._id)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }
  
  const getCertificate = () => {
    axios.post(url() + 'api/admin/certificates/list', {}).then(response => {
      if (response?.status == 200) {
        const certificate = (response?.data?.data || []).find(c => c._id == id)
  
        // set content
        setSelectedCourse(certificate?.course_id || '')
        setContent(certificate?.certificate || '')
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }
  
  useEffect(() => {
    getCourses()
  }, [])
  
  useEffect(() => {
    if (id) {
      getCertificate()
    }
  }, [courses])
  

  const addCertificate = () => {
    let formData = new FormData()
    formData.append('content', content)
    formData.append('course_id', selectedCourse)

    axios.post(url() + 'api/admin/certificates', formData).then(response => {
      if (response?.status == 200) {
        setSuccess('Certificate successfully added.')
        window.setTimeout(() => {
          history.push(`/certificates`)
        }, 1000)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const updateCertificate = () => {
    let formData = new FormData()
    formData.append('content', content)
    formData.append('course_id', selectedCourse)

    axios.put(url() + 'api/admin/certificates/' + id, formData).then(response => {
      if (response?.status == 200) {
        setSuccess('Certificate successfully updated.')
        window.setTimeout(() => {
          history.push(`/certificates`)
        }, 1000)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const handleEditorChange = (value) => {
    setContent(value);
    // console.log('value : ' , content)
  };




  return (
    <div className="add-lesson-view bg-light">
        <button
            className="position-absolute d-flex align-items-center mt-2 btn btn-secondary"
            onClick={() => history.goBack()}
          ><BiArrowBack className="back-arrow" /></button>
      <div className="edit-box">
        <h4 className="edit-box-title">{id ? 'Update' : 'Add'} Certificate</h4>
        <div className="row mb-3">
          <div className="col-xl-4 col-lg-12 col-sm-12 mb-3">
            <h6 className="edit-box-label ml-2 mb-2">Select Course</h6>
            <select className="form-select"
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              {courses.map(course => {
                return (
                  <option
                    value={course._id}
                    key={course._id}
                  >{course.name}</option>
                )
              })}
            </select>
          </div>
          {/*
          <div className="col-xl-5 col-lg-8 col-sm-12 mb-3">
            <h6 className="ml-2 mb-2">Lesson Title</h6>
            <input
              type="text"
              className="form-control"
              placeholder="Title"
              value={title}
              onInput={(e) => setTitle(e.target.value)}
              onChange={() => {}}
            />
          </div>
          */}
        </div>
        <div className="row h-100 mb-3">
          <div className="col-12">
            <h6 className="ml-2">Certificate Content</h6>
               <ReactQuill
                       forwardedRef={editorRef}
                       className="editor"
                       theme="snow"
                       modules={modules}
                       formats={formats}
                       value={content}
                       onChange={handleEditorChange}
                      style={{minHeight:350}}
                     />
          </div>
        </div>
        <div className="row" style={{marginTop:'100px'}}>
          <div className="col-12 d-flex justify-content-end">
            <button onClick={id ? updateCertificate : addCertificate} className= "main-btns">Save</button>
            <button onClick={() => history.goBack()} className= "main-btns" >Cancel</button>
          </div>
        </div>

        {error ? (
          <div className="alert alert-danger mt-3">
            {error}
          </div>
        ) : <></>}

        {success ? (
          <div className="alert alert-success mt-3">
            {success}
          </div>
        ) : <></>}
      </div>
    </div>
  );
}
