import React, { useState, useEffect } from "react";
import Pagination from '../components/Pagination'
import { url } from '../actions/config'
import axios from 'axios'


export default function Users() {

  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(null)
  const [users, setUsers] = useState([])
  const [error, setError] = useState('')
  const [loading , setIsLoading] = useState(false);

  const getUsers = () => {
    setIsLoading(true)
    axios.post(url() + 'api/admin/listUsers', {
      skip: skip,
      limit: limit
    }).then(response => {
      if (response?.status == 200) {
        setUsers(response?.data?.data)
        if (response?.data?.total) setTotal(response?.data?.total)
        setIsLoading(false)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getUsers()
  }, [skip])

  return (
    <div className="card bg-light m-5">
      <div className="card-body">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-2 mb-3">
          <h5 className="ml-2">Users</h5>
          <Pagination total={total} limit={limit} setSkip={setSkip} users={users} />
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Wallet Address</th>
                <th scope="col">Name</th>
                <th scope="col">Joined</th>
                <th scope="col">Is Active</th>
                {/* <th scope="col">Email Verified</th> */}
                {/* <th scope="col">Role</th> */}
                {/* <th scope="col">Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {loading && (
                  <div className="row my-4 mt-2">
                  <div className="m-auto spinner-border text-info"></div>
                </div>
              )}

              {!loading && users.map((user) => {
                return (
                  <tr key={user._id}>
                    <td>
                      {user.wallet_address?.length
                        ? `${user.wallet_address.slice(0, 5)}...${user.wallet_address.slice(-5)}`
                        : ''}
                    </td>
                    <td>{user.name}</td>
                    <td>{new Date(user.created_at).toLocaleString()}</td>
                    <td>{user.is_active ? 'Yes' : 'No'}</td>
                    {/* <td>{user.email_verified ? 'Yes' : 'No'}</td> */}
                    {/* <td>{user.role}</td> */}
                    {/* <td>
                      <button className="btn btn-sm btn-primary">Some Action</button>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

}
