import { Networks } from "./blockchain";


const MATIC_MAINNET = {
    SEVEN_MONEY_ADDRESS: "0x6476Be61EAd686BB8f39436F3878b2b33C888050"
}

export const getAddresses = (networkID) => {
    if (networkID === Networks.MATIC) return MATIC_MAINNET;
    throw Error("Network don't support");
};
