import React, { useState, useContext, useMemo, useCallback } from "react";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Web3Provider } from "@ethersproject/providers";
import { switchNetwork } from "./helpers/swtich-networks";
import { ethers } from "ethers";
import { Networks } from "../constants";

import { getMainnetURI } from "./helpers/get-mainnet-uri";

const Web3Context = React.createContext();

export const useWeb3Context = () => {
    const web3Context = useContext(Web3Context);
    if (!web3Context) {
        throw new Error("useWeb3Context() can only be used inside of <Web3ContextProvider />, " + "please declare it at a higher level.");
    }
    const { onChainProvider } = web3Context;
    return useMemo(() => {
        return { ...onChainProvider };
    }, [web3Context]);
};

export const useAddress = () => {
    const { address } = useWeb3Context();
    return address;
};

export const Web3ContextProvider = ({ children }) => {

    const [connected, setConnected] = useState(false);
    const [chainID, setChainID] = useState(1);
    const [providerChainID, setProviderChainID] = useState();
    const [address, setAddress] = useState("");
    const [balance, setBalance] = useState("0");
    const [networkName, setNetworkName] = useState("");
    const [provider, setProvider] = useState(getMainnetURI());

    const [web3Modal] = useState(
        new Web3Modal({
            cacheProvider: true,
            theme: "dark",
            providerOptions: {
                walletconnect: {
                    package: WalletConnectProvider,
                    options: {
                        rpc: {
                            [Networks.POLYGON]: getMainnetURI()
                        }
                    }
                }
            }
        })
    );

    const hasCachedProvider = () => {
        if (!web3Modal) return false;
        if (!web3Modal.cachedProvider) return false;
        return true;
    };

    const _initListeners = useCallback(
        (rawProvider) => {
            if (!rawProvider.on) {
                return;
            }

            rawProvider.on("accountsChanged", () => setTimeout(() => window.location.reload(), 1));

            rawProvider.on("chainChanged", async (chain) => {
                changeNetwork(chain);
                window.location.reload()
            });

            rawProvider.on("network", (_newNetwork, oldNetwork) => {
                if (!oldNetwork) return;
                window.location.reload();
            });
        },
        [provider]
    );

    const changeNetwork = async (otherChainID) => {
        connect()
        const network = Number(otherChainID);
        setProviderChainID(network);
    };

    const connect = useCallback(async () => {

        try {
            const rawProvider = await web3Modal.connect();
            _initListeners(rawProvider);
            const connectedProvider = new Web3Provider(rawProvider, "any");
            const chainId = await connectedProvider.getNetwork().then(network => Number(network.chainId));
            const connectedAddress = await connectedProvider.getSigner().getAddress();
            const networkName = await connectedProvider.getNetwork().then(network => String(network.name))

            const balance = ethers.utils.formatEther(await connectedProvider.getBalance(connectedAddress));

            setAddress(connectedAddress);
            setBalance(balance);
            setProviderChainID(chainId);
            setChainID(chainId);
            setNetworkName(networkName);
            setProvider(connectedProvider);
            setConnected(true);

            return connectedProvider;

        } catch (error) {
            console.log(error);
        }

    }, [provider, web3Modal, connected]);

    const checkWrongNetwork = () => {

        if (providerChainID == 1 || providerChainID == 137) {
            return false;
        }
        return true;
    };

    const disconnect = useCallback(async () => {
        web3Modal.clearCachedProvider();
        setConnected(false);
        setTimeout(() => {
            window.location.reload();
        }, 1);
    }, [provider, web3Modal, connected]);

    const onChainProvider = useMemo(
        () => ({
            connect,
            disconnect,
            switchNetwork,
            hasCachedProvider,
            provider,
            connected,
            address,
            balance,
            networkName,
            chainID,
            web3Modal,
            providerChainID,
            checkWrongNetwork
        }),
        [connect, disconnect, hasCachedProvider, provider, connected, address, networkName, balance, chainID, web3Modal, providerChainID]
    );
    //@ts-ignore
    return <Web3Context.Provider value={{ onChainProvider }}>{children}</Web3Context.Provider>;
};

export default Web3ContextProvider;