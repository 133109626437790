import React from "react";
import {MdOutlineNavigateBefore} from "react-icons/md";
import {MdNavigateNext} from "react-icons/md";
// import { useParams } from 'react-router-dom'


const LessonDetail = () => {
  return (
    <div className="lesson-view">
      <div className="before-lesson-button">
        <MdOutlineNavigateBefore
          color="#ccc"
          style={{width: "30px", height: "30px", marginRight: "30px"}}
        />
      </div>
      <div className="lesson-detail-container">
        <div className="w-100 flex flex-column" style={{zIndex: 10}}>
          <div className="lesson-title">
            <span>Lesson : Create Your First Smart Contract</span>
          </div>
          <div className="lesson-view">
            <div>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <div className="next-lesson-button">
        <MdNavigateNext
          color="#ccc"
          style={{width: "30px", height: "30px", marginLeft: "30px"}}
        />
      </div>
    </div>
  );
};

export default LessonDetail;
