
const env = 'prod'

export const url = () => {
  switch (env) {
    case 'prod':
      return 'https://api.web3fool.com/'
    case 'test':
      return 'https://w3api.ceewen.xyz/'
    default: ///dev
      return 'http://422b-124-123-186-253.ngrok-free.app/'
  }
}
