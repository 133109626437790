import React, { useState, useEffect , useRef } from "react";
import { useParams, useHistory } from 'react-router-dom'
import { url } from '../actions/config'
import axios from 'axios'
import { EditorState, ContentState , convertToRaw, convertFromRaw , Modifier , OrderedMap , EntityMap } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from 'react-quill';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module-react';  
import { BiArrowBack } from "react-icons/bi";
import 'react-quill/dist/quill.snow.css';
import Modal from "react-bootstrap/Modal";



Quill.register('modules/imageResize', ImageResize);



export default function AddLesson() {

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [courses, setCourses] = useState([])
  const [course, setCourse] = useState({})
  const [contentType, setContentType] = useState('text')
  const [courseTitle, setCourseTitle] = useState('')
  const [title, setTitle] = useState('')
  const [lessonSubIndex, setLessonSubIndex] = useState('')
  const [number, setNumber] = useState(undefined)
  const [content, setContent] = useState('')
  const [video, setVideo] = useState(undefined)
  const [imageFile, setImageFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(undefined)
  const [isUploading, setIsUploading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [deleteModal , setDeleteModal] = useState(false);

  const { course_id, lesson_id } = useParams()
  const history = useHistory()
  const editorRef = useRef(null);

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB


  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image' ],
      [
        {
          color: ["red", "blue", "yellow" , "green" , "black" , "pink" , "brown"]
        }
      ],
      ['clean']
    ],
    imageResize: {
      displaySize: true,
      handleStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white'
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    }
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image','color','width'
  ];

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleClose = () => {
    setDeleteModal(false)
  }

  const deleteLesson = () => {
    setDeleteModal(false)
    axios.delete(url() + 'api/admin/lessons/' + lesson_id).then(response => {
      if (response?.status == 200) {
       setSuccess('Lesson successfully deleted.')
        window.setTimeout(() => {
          history.push(`/course/${course_id}/lessons`)
        }, 1000)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const getCourses = () => {
    axios.post(url() + 'api/admin/courses/list').then(response => {
      if (response?.status == 200) {
        const courses = response?.data?.data || []
        setCourses(courses)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }


  const getLessonDetails = async () => {
    try {
      const response = await axios.get(url() + 'api/admin/lessons/' + lesson_id);
      if (response?.status == 200) {
        const course = response?.data?.course_id || {};
        setCourse(course);

        const course_title = response?.data?.data?.course_id?.name || '';
        const name = response?.data?.data?.name || '';
        const lessonIndex = response?.data?.data?.sub_index || ''
        const sequence_number = response?.data?.data?.sequence_number;
        const content_type = response?.data?.data?.content_type || 'text';
        const videoPath = response?.data?.data?.videoPath || '';
        const content = response?.data?.data?.content || '';
        setCourseTitle(course_title)
        setTitle(name);
        setNumber(sequence_number);
        setContentType(content_type);
        setLessonSubIndex(lessonIndex)
  
        if (videoPath) {
          setVideoPreview(url() + 'course_videos/' + videoPath);
        }
  
        if (content) {
          setContent(content)
        }
      }
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.msg);
    }
  }

  useEffect(() => {
    // getCourses()
    getLessonDetails()
  }, [])

  const handleUpload = async (file) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('content_type', contentType);
      formData.append('lesson_image', file);
  
      const response = await axios.post(url() + 'api/admin/uploadFile', formData);
      setIsUploading(false);
  
      return url() + response.data.data; 
    } catch (error) {
      console.error(error);
    }
  };

  const parseEditorContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const imgElements = doc.querySelectorAll('img');
    const imageSrcs = Array.from(imgElements)
      .map((img) => img.src)
      .filter((src) => src.startsWith('data:image/'));
  
    return imageSrcs;
  };
  
  const uploadImages = async (imageSrcs) => {
    let uploadedContent = content;
  
    for (const src of imageSrcs) {
      const file = await fetch(src)
        .then((response) => response.blob())
        .then((blob) => new File([blob], 'image.jpg', { type: 'image/jpeg' }));
  
      const imageUrl = await handleUpload(file);
  
      uploadedContent = uploadedContent.replace(src, imageUrl);
    }
  
    return uploadedContent;
  };


  const updateLesson = async() => {
    setIsUploading(true)
    const parsedContent = parseEditorContent(content);
    const uploadedContent = await uploadImages(parsedContent);
    let formData = new FormData()
    formData.append('name', title)
    formData.append('sub_index', lessonSubIndex);
    formData.append('content_type', contentType)
    if (contentType == 'text' && uploadedContent) {
      formData.append('content', uploadedContent)
    }
    if (contentType == 'video' && video) {
      formData.append('content', video)
    }
    formData.append('course_id', course_id)
    axios.put(url() + 'api/admin/lessons/' + lesson_id, formData).then(response => {
      if (response?.status == 200) {
        setSuccess('Lesson successfully updated.')
        window.setTimeout(() => {
          history.push(`/course/${course_id}/lessons`)
        }, 1000)
      }
      setIsUploading(false)
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
      setIsUploading(false)
    })
  }

  const handleSelectVideo = async (e) => {
    const file = e.target.files[0];
    let src = URL.createObjectURL(file);
    if (!file) return;
    const fileSize = file.size;
    if (fileSize > MAX_FILE_SIZE) {
      setError('Please select video less than 10mb');
      return;
    } else {
      setVideo(file);
      setVideoPreview(src);
    }
  };

  const openFileSelect = () => {
    const fileSelect = document.getElementById("upload-video");
    fileSelect.click()
  }

  const clearVideo = () => {
    setVideo(undefined)
    setVideoPreview(undefined)
  }




  return (
    <>
      <h4 className="secondary-text text-center my-5">{course?.name}</h4>

      <div className="add-lesson-view mt-5 bg-light">
        <button
            className="position-absolute d-flex align-items-center mt-2 btn btn-secondary"
            onClick={() => lesson_id?.course_id ? history.push(`/course/${lesson_id?.course_id?._id}/lessons`) : history.goBack()}
          ><BiArrowBack className="back-arrow" /></button>
        <div className="edit-box">
          <h4 className="edit-box-title">Update Lesson</h4>
          <div className="row mb-3">
            <div className="col-xl-5 col-lg-8 col-sm-12 mb-3">
              <h6 className="edit-box-label ml-2 mb-2">Lesson Title</h6>
              <input
                type="text"
                className="form-control"
                placeholder="Title"
                value={title}
                onInput={(e) => setTitle(e.target.value)}
                onChange={() => {}}
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-12 mb-3">
               <h6 className="edit-box-label ml-2 mb-2">Lesson Index</h6>
               <input
                type="text"
                className="form-control"
                placeholder="Lesson Number"
                value={lessonSubIndex}
                onInput={(e) => setLessonSubIndex(e.target.value)}
              />
             </div>
            {contentType == 'video' ? (
              <div className="col-xl-5 col-lg-8 col-sm-12 mb-3">
                <h6 className="edit-box-label ml-2 mb-2">Lesson Content</h6>
                <div
                  className={`file-dragger video ${videoPreview ? 'preview' : ''}`}
                  onClick={openFileSelect}
                >
                  {videoPreview ? (
                    <video width="100%" controls style={{ objectFit: 'cover' }}>
                      <source src={videoPreview} />
                    </video>
                  ) : (
                    <h6>Upload Video</h6>
                  )}
                </div>
                <input
                  id="upload-video"
                  type="file"
                  accept="video/*"
                  autoComplete="off"
                  tabIndex={-1}
                  style={{display: "none"}}
                  onChange={handleSelectVideo}
                />
                {!isUploading && (
                  <div className="row mt-2">
                    <button
                      className="mx-auto mr-3"
                      onClick={clearVideo}
                      style={{borderRadius : '8px',color : 'white' , background:'red ' , paddingLeft: '5px' , paddingRight:'5px'}}
                    >Delete</button>
                  </div>
                )}
              </div>
            ) : (
              // <div className="col-xl-5 col-lg-8 col-sm-12 mb-3">
              <div className="row m-3">
                <h6 className="edit-box-label ml-2">Lesson Content</h6>
                <div className="col-xl-12">
                   <ReactQuill
                       forwardedRef={editorRef}
                       className="editor"
                       theme="snow"
                       modules={modules}
                       formats={formats}
                       value={content}
                       onChange={handleEditorChange}
                      style={{minHeight:350}}
                     />
                </div>
              </div>
            )}
          </div>

          {isUploading ? (
            <div className="row my-4">
              <div className="m-auto spinner-border text-info"></div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 d-flex justify-content-end" style={{marginTop:'80px'}}>
                <button onClick={updateLesson} className= "main-btns">Save</button>
                <button onClick={() => {setDeleteModal(true)}} className= "main-btns">Delete</button>
                <button onClick={() => history.goBack()} className= "main-btns">Cancel</button>
              </div>
            </div>
          )}

          {error ? (
            <div className="alert alert-danger mt-3">
              {error}
            </div>
          ) : <></>}

          {success ? (
            <div className="alert alert-success mt-3">
              {success}
            </div>
          ) : <></>}
        </div>
      </div>

        {/* DELETE LESSON MODAL VIEW */}
        <Modal show={deleteModal}  aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
        <Modal.Header className="modal-header">
          <Modal.Title id="contained-modal-title-vcenter">Lesson Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <p>
              Are you sure you want to delete {title}? Please be aware that some users may have already started this lesson. If the lesson is deleted, they will no longer have access to it.
           </p>
         </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-danger font-weight-bold mt-3" onClick={deleteLesson}>
               Delete
            </button>
            <button className="btn btn-danger font-weight-bold mt-3" onClick={handleClose}>
               Close
            </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
