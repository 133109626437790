import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import {TbPlus} from "react-icons/tb";
import { url } from '../actions/config'
import axios from 'axios'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
  Image
} from '@react-pdf/renderer';
import {parse} from 'html-parse-stringify2';


const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: 20,
    textAlign: 'center',
    backgroundColor: '#fff'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    flexGrow: 1,
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: '#787878',
    borderWidth: '5pt'
  },
  text: {
    marginTop: 8,
    marginBottom: 8
  }
});

const tagStyles = {
  h1: {
    fontSize: '40pt'
  },
  h2: {
    fontSize: '32pt'
  },
  h3: {
    fontSize: '28pt'
  },
  h4: {
    fontSize: '24pt'
  },
  h5: {
    fontSize: '20pt'
  },
  h6: {
    fontSize: '16pt'
  },
  strong: {
    fontFamily: 'Helvetica-Bold'
  },
  em : {
    fontStyle : 'italic',
    fontFamily: 'Helvetica-Oblique'
  }
}

const getParsedHTMLLines = (html) => {
  const parsed = parse(html);
  const lines = [];

  const traverse = (node, parentTags, prevLineHasStyle) => {
    if (node.type === 'text') {
      const content = node.content.trim();
      if (content) {
        const currentLine = { content, tags: parentTags };
        if (!prevLineHasStyle || parentTags.length > 0) {
          lines.push(currentLine);
        } else {
          const lastLine = lines[lines.length - 1];
          lastLine.content += ' ' + currentLine.content;
        }
        prevLineHasStyle = parentTags.length > 0;
      }
    } else if (node.type === 'tag') {
      const tags = parentTags.concat(node.name);
      if (node.name === 'img') {
        // Handle image tag
        const src = node.attrs.src;
        const alt = node.attrs.alt || '';
        const currentLine = { content: <Image src={src} alt={alt} style={{ width: 150, height: 150 }} />, tags };
        if (!prevLineHasStyle) {
          lines.push(currentLine);
        } else {
          const lastLine = lines[lines.length - 1];
          lastLine.content = <>{lastLine.content} {currentLine.content}</>;
        }
        prevLineHasStyle = false;
      } else {
        node.children.forEach(child => traverse(child, tags, prevLineHasStyle));
        prevLineHasStyle = false;
      }
    }
  };
  

  parsed.forEach(node => traverse(node, []));

  return lines;
};


// Create Document Component
const Certificate = ({ html }) => {
 const lines = getParsedHTMLLines(html)
 
  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        <View style={styles.section}>
          {lines.map((line, index) => {
            const lineStyle = (line.tags || []).map(tag => tagStyles[tag] || {})
            return (
              <Text style={[styles.text, { fontFamily: 'Helvetica' }, ...lineStyle]} key={index}>{line.content}</Text>
            )
          })}
        </View>
      </Page>
    </Document>
  )
}

export default function Certificates() {

  const [isLoading, setIsLoading] = useState(true);
  const [certificates, setCertificates] = useState([])
  const [pdfs, setPDFs] = useState({})
  const pdfs_ref = useRef({})
  const [showingCertificate, setShowingCertificate] = useState(null)
  const [error, setError] = useState(null)
  const [is401, setIs401] = useState(false)

  const history = useHistory()


  const getCertificates = () => {
    axios.post(url() + 'api/admin/certificates/list', {}).then(response => {
      if (response?.status == 200) {
        const certificatesData = response?.data?.data || []
        // console.log('certificates', certificatesData);
        setCertificates(certificatesData)
        certificatesData.forEach((certificate, index) => {
          getPDFSource(certificate?._id)
        });
      }
      setIsLoading(false)
    }).catch(error => {
      console.error(error);
      // if (error?.response?.status == 401) {
      //   setIs401(true)
      // }
      setError(error?.response?.data?.msg)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getCertificates()
  }, [])

  const waitForElement = (id) => {
    return new Promise((resolve, reject) => {
      if (document.getElementById(id)) {
        return resolve(document.getElementById(id))
      }

      const observer = new MutationObserver((mutationList, observer) => {
        if (document.getElementById(id)) {
          resolve(document.getElementById(id))
          observer.disconnect()
        }
      })

      observer.observe(document.body, {
        childList: true,
        subtree: true
      })
    })
  }

  const getPDFSource = (id) => {
    waitForElement(id).then(pdf => {
      window.setTimeout(() => {
        setPDFs({ ...pdfs_ref.current, [id]: pdf?.src })
        pdfs_ref.current[id] = pdf?.src
      }, 100)
    })
  }



  const openCertificate = (id) => {
    window.open(pdfs[id], '_blank').focus()
  }



  return !isLoading && (
    <div className="certificate-view">
      {is401 ? (
        <div className="col-12 rounded bg-light mt-3 p-4">
          <p className="mb-0 text-center">Please <Link to="/dashboard">login</Link> to continue.</p>
        </div>
      ) : <></>}

      <Link className="btn btn-primary mr-2" to="/certificates/add">
        <TbPlus color="white" />
        <span>Add Certificate</span>
      </Link>

      {!is401 && !certificates?.length ? (
        <div className="bg-light p-4 rounded">
          <p>There are no certificates.</p>
        </div>
      ) : (
        <>
          <h5 className="secondary-text text-center mb-4">Certificates</h5>
          <div className="card_box">
            {certificates.map((certificate, index) => {
              return (
                <div className="card-demo" key={index}>
                  <div className="certificate bg-light_2 mb-2 " key={index}>
                    <h4 className="primary-text mb-4 p-2">{certificate?.course_name}</h4>
                    <div className="d-inline-flex btn-group category p-2">
                      <p>{certificate?.category_name}</p>
                    </div>
                    <div className="mt-auto p-2 mb-2">
                      <button
                        className="btn btn-secondary btn-sm mr-2"
                        onClick={() => openCertificate(certificate?._id)}
                      >Preview</button>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => history.push(`/certificates/update/${certificate._id}`)}
                      >Edit</button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}

      {showingCertificate ? (
        <div className="view-certificate-container">
          <div className="view-certificate">
            <div className="certificate-border">
              <div
                className="certificate-content"
                dangerouslySetInnerHTML={{ __html: showingCertificate.certificate }}
              ></div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                className="btn btn-secondary"
                onClick={() => setShowingCertificate(null)}
              >Close</button>
            </div>
          </div>
        </div>
      ) : <></>}

      {certificates.map((certificate, index) => {
        return (
          <div className="d-none" key={certificate?._id}>
            <PDFViewer id={certificate?._id}>
              <Certificate html={certificate?.certificate} />
            </PDFViewer>
          </div>
        )
      })}

    </div>
  );
}
