import React, { useEffect, useState } from "react";
import LessonCard from "../components/LessonCard";
import {TbPlus} from "react-icons/tb";
import {Link} from "react-router-dom";
import { useParams, useHistory } from 'react-router-dom'
import { url } from '../actions/config'
import axios from 'axios'
import { FaInfoCircle } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";



export default function LessonList() {

  const [course, setCourse] = useState({})
  const [lessons, setLessons] = useState([])
  const [courseDesc , setCourseDesc] = useState("");
  const [courseName , setCourseName] = useState("");
  const [error, setError] = useState(null)
  const [dragIndex, setDragIndex] = useState(null)
  const [dragObject, setDragObject] = useState(null)
  const [viewModal , setViewModal] = useState(false);


  const { id } = useParams()

  const getCourseDetails = () => {
    axios.get(url() + 'api/admin/courses/' + id).then(response => {
      if (response?.status == 200) {
        const course = response?.data?.data?.[0]
        setCourse(course)
        setLessons(course?.lessons || [])
        setCourseDesc(course?.description || "")
        setCourseName(course?.name || "")
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const dragEnter = (index) => {
    setDragIndex(index)
  }

  const dragEnd = () => {
    setError(null)

    let newLessons = [...lessons]
    newLessons.splice(dragObject.index, 1)
    const insertAtIndex = dragIndex > dragObject.index ? dragIndex - 1 : dragIndex
    newLessons.splice(insertAtIndex, 0, dragObject)
    setLessons(newLessons)
    setDragIndex(null)
    setDragObject(null)

    const lessonIDs = newLessons.map(lesson => lesson._id)
    let params = {
      lessonIds: lessonIDs
    }

    axios.post(url() + 'api/admin/lessons/reorder/' + course?._id, params).then(response => {
      console.log(response);
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  useEffect(() => {
    getCourseDetails()
  }, [])


  return (
    <div style={{paddingTop: "100px"}} className="lesson-card-view"
      onDragEnd={dragEnd}
    >
     <div className="row" style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
        <Link className="add-lesson-button" to={`/course/${id}/add-lesson`} style={{ marginRight: '10px' }}>
          <TbPlus color="white" />
            Add Lesson
        </Link>

        {/* <div style={{ marginLeft: 'auto', marginRight: '10px' , marginBottom:'15px'}}> */}
          <button className="add-lesson-button" style={{border :'none'}}onClick={()=>{setViewModal(true)}} >Course Details </button>
        {/* </div> */}
      </div>

     

      {error ? (
        <p className="text-danger">{error}</p>
      ) : <></>}

      <div>
        {!lessons?.length ? (
          <p className="text-white">There are no lessons for this course.</p>
        ) : <></>}
        {lessons.map((lesson, index) => (
          <div
            onDragStart={() => setDragObject({ ...lesson, index })}
            onDragEnter={() => dragEnter(index)}
            onDragOver={(e) => e.preventDefault()}
            key={lesson._id}
          >
            {dragIndex == index && (
              <div className="py-3">
                <hr className="m-0" />
              </div>
            )}
            <div className="py-2">
              <LessonCard
                course_id={id}
                lesson_id={lesson._id}
                title={lesson.name}
                index={lesson.sub_index ? lesson.sub_index : index +1}
              />
            </div>
          </div>
        ))}

        {dragIndex != null && (
          <div
            onDragEnter={() => setDragIndex(lessons?.length)}
            onDragOver={(e) => e.preventDefault()}
          >
            <div className="py-3">
              <hr className="m-0" />
            </div>
          </div>
        )}
      </div>

{/* COURSE DETAILS VIEW */}
<Modal show={viewModal} 
      aria-labelledby="contained-modal-title-vcenter"
      size="lg" 
      centered 
      onHide={() => {setViewModal(false)}} 
      >
  <Modal.Body style={{ maxHeight: 'calc(70vh - 200px)', overflowY: 'auto' , marginLeft:'20px'}}>
    <div className="row">
      <div>
        <b>Category:</b> <br />
        {course?.category_id?.name?.toUpperCase()}
      </div>
    </div>
    <div className="row mt-2">
      <div>
        <b>Title:</b>  <br />
        {courseName}
      </div>
    </div>
    <div className="row mt-2">
      <div>
        <b>Description:</b> <br />
        {courseDesc}
      </div>
    </div>
    <div className="row mt-2">
      <div>
        <b>Image:</b><br />
        <img src={url() + course?.banner_image} alt="Course Img" height={200} width={300}/>
      </div>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Link className="btn btn-danger font-weight-bold mt-3 rounded" type="button" to={`/courses/update/${id}`}>
      Edit Course
    </Link>
    <button className="btn btn-danger font-weight-bold mt-3 rounded" onClick={() => {setViewModal(false)}}>
      Close
    </button>
  </Modal.Footer>
</Modal>

    </div>

    
  );
}
