import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from 'react-router-dom'
import { url } from '../actions/config'
import axios from 'axios'
// import { Editor } from '@tinymce/tinymce-react';
// import {Editor} from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, convertFromRaw } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html'
import htmlToDraft from 'html-to-draftjs'
import ReactQuill from 'react-quill';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module-react';


// import 'react-quill/dist/quill.snow.css';


Quill.register('modules/imageResize', ImageResize);

export default function AddLesson() {

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [courses, setCourses] = useState([])
  const [course, setCourse] = useState({})
  const [contentType, setContentType] = useState('text')
  const [title, setTitle] = useState('')
  const [lessonSubIndex, setLessonSubIndex] = useState('')
  const [number, setNumber] = useState(undefined)
  const [content, setContent] = useState('')
  const [video, setVideo] = useState(undefined)
  const [videoPreview, setVideoPreview] = useState(undefined)
  const [isUploading, setIsUploading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [chunkOffsets, setChunkOffsets] = useState([0]);


  const { course_id, lesson_id } = useParams()
  const history = useHistory()
  const editorRef = useRef();

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB


  const getCourseDetails = async () => {
    try {
      const response = await axios.get(url() + 'api/admin/courses/' + course_id)
      if (response?.status == 200) {
        const course = response?.data?.data?.[0] || {}
        // console.log(course)
        setCourse(course)

        if (lesson_id) {
          const lesson = (course?.lessons || []).find(lesson => lesson._id == lesson_id)

          const { name, sequence_number, content_type, content, videoPath } = lesson || {}
          setTitle(name || '')
          setNumber(sequence_number)
          setContentType(content_type || 'text')
          if (videoPath) {
            setVideoPreview(url() + 'course_videos/' + videoPath)
            // console.log(url() + 'course_videos/' + videoPath)
          }

          const html = content || ''
          const contentBlock = htmlToDraft(html)
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            const editorStateInitial = EditorState.createWithContent(contentState)
            setEditorState(editorStateInitial)
          }
        }
      }
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.msg)
    }
  }

  useEffect(() => {
    getCourseDetails()
  }, [])



  // const handleUpload = async(file) => {
  //   setIsUploading(true)
  //     const formData = new FormData();
  //     formData.append('content_type', contentType);
  //     formData.append('content', file); 
  //     try {
  //       const response = await axios.post(url() + 'api/admin/uploadFile', formData);
  //       const imageUrl = url() + response.data.data; // assuming API returns the image URL in 'url' field
  //       console.log('img url : ' , imageUrl)
  //       setIsUploading(false)
  //       // return imageUrl;
  //       // const range = this.quill.getSelection();
  //       // this.quill.insertEmbed(range.index, 'image', imageUrl);
  //     } catch (error) {
  //       console.error(error);
  //     }
  // };

  // const handleImageUpload = async () => {
  //   try {
  //     const file = await handleUpload(); // replace with your file upload function
  //     const fileSize = file.size / 1024 / 1024; // convert to MB
  //     if (fileSize > 2) { // check if file size is larger than 2MB
  //       alert('Image size too large, please choose a smaller image.');
  //       return;
  //     }
  //     const range = editorRef.current?.getEditor().getSelection(true);
  //     const index = range ? range.index : editorRef.current?.getEditor().getLength();
  //     editorRef.current?.getEditor().insertEmbed(index, 'image', file.url); // insert the image
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      //  ['link', { 'image': handleUpload }], // add custom button for image upload
      ['link', 'image'], //removed 'video'
      [
        {
          color: ["red", "blue", "yellow", "green", "black", "pink", "brown"]
        }
      ],
      ['clean']
    ],
    imageResize: {
      handleStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white'
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    }
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color'
  ];

  const handleUpload = async (file) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('content_type', contentType);
      formData.append('lesson_image', file);

      const response = await axios.post(url() + 'api/admin/uploadFile', formData);
      setIsUploading(false);

      return url() + response.data.data; // assuming API returns the image URL in 'url' field
    } catch (error) {
      console.error(error);
    }
  };




  // const parseEditorContent = (content) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(content, 'text/html');
  //   const imgElements = doc.querySelectorAll('img');
  //   const imageSrcs = Array.from(imgElements).map((img) => img.src);
  //   return imageSrcs;
  // };

  const parseEditorContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const imgElements = doc.querySelectorAll('img');
    const imageSrcs = Array.from(imgElements)
      .map((img) => img.src)
      .filter((src) => src.startsWith('data:image/'));

    return imageSrcs;
  };

  const uploadImages = async (imageSrcs) => {
    let uploadedContent = content;

    for (const src of imageSrcs) {
      const file = await fetch(src)
        .then((response) => response.blob())
        .then((blob) => new File([blob], 'image.jpg', { type: 'image/jpeg' }));

      const imageUrl = await handleUpload(file);

      uploadedContent = uploadedContent.replace(src, imageUrl);
    }

    return uploadedContent;
  };


  const addLesson = async () => {
    setIsUploading(true);

    try {
      const parsedContent = parseEditorContent(content);
      const uploadedContent = await uploadImages(parsedContent);

      const formData = new FormData();
      formData.append('name', title);
      formData.append('sub_index', lessonSubIndex);
      formData.append('content_type', contentType);
      if (uploadedContent) {
        formData.append('content', uploadedContent);
      }
      // formData.append('content', content);
      if (contentType === 'video' && video) {
        formData.append('content', video);
      }
      formData.append('course_id', course_id);
      const response = await axios.post(url() + 'api/admin/lessons', formData);
      // console.log('resp : ' ,response)

      if (response?.status === 200) {
        setSuccess('Lesson successfully added.');
        window.setTimeout(() => {
          history.push(`/course/${course_id}/lessons`);
        }, 1000);
      }

    } catch (error) {
      console.log('errrr : ', error);
      setError(error?.response?.data?.msg);
    }
    setIsUploading(false);
  };

  const openFileSelect = () => {
    const fileSelect = document.getElementById("upload-video");
    fileSelect.click()
  }

  const clearVideo = () => {
    setVideo(undefined)
    setVideoPreview(undefined)
  }

  // const uploadVideoChunks = async (file) => {
  //   const chunks = [];
  //   const offsets = [];
  //   let offset = 0;

  //   while (offset < file.size) {
  //     const chunkSize = Math.min(CHUNK_SIZE, file.size - offset);
  //     const chunk = file.slice(offset, offset + chunkSize);
  //     chunks.push(chunk);
  //     offsets.push(offset);
  //     offset += chunkSize;
  //   }

  //   return { chunks, offsets };
  // };

  const handleSelectVideo = async (e) => {
    const file = e.target.files[0];
    let src = URL.createObjectURL(file);
    if (!file) return;
    const fileSize = file.size;
    if (fileSize > MAX_FILE_SIZE) {
      setError('Please select video less than 10mb');
      return;
    } else {
      setVideo(file);
      setVideoPreview(src);
    }
  };


  // const handleSelectVideo = async(e) => {
  //   const file = e.target.files[0]
  //   console.log('files : ' , file)
  //   if (!file) return;
  //   setVideo(file);
  //   let src = URL.createObjectURL(file);
  //   setVideoPreview(src);
  // }

  // try {
  //   const formData = new FormData();
  //   formData.append('video', file);
  //   formData.append('contentType', contentType);
  //     const response = await axios.post(url() + 'api/admin/uploadFile', formData);
  //     console.log('resp url : ' , response.data)
  //     const imageUrl = url() + response.data.data; // assuming API returns the image URL in 'url' field
  //     console.log('vid url : ' , imageUrl)
  //     setVideo(imageUrl);
  //   } catch (error) {
  //     console.error(error);
  //   }

  const handleEditorChange = (value) => {
    setContent(value);
  };


  return (
    <>
      <h4 className="secondary-text text-center my-5">{course?.name}</h4>

      <div className="add-lesson-view mt-5 bg-light">

        <div className="edit-box">
          <h4 className="edit-box-title">Add Lesson</h4>
          <div className="row mb-3">
            <div className="col-xl-5 col-lg-8 col-sm-12 mb-3">
              <h6 className="edit-box-label ml-2 mb-2">Lesson Title</h6>
              <input
                type="text"
                className="form-control"
                placeholder="Title"
                value={title}
                maxLength={40}
                onInput={(e) => setTitle(e.target.value)}
              />
            </div>
            {/* <div className="col-xl-5 col-lg-8 col-sm-12 mb-3">
              <h6 className="edit-box-label ml-2 mb-2">Content Type</h6>
              <select value={contentType} onChange={(e) => setContentType(e.target.value)}>
                <option value='text'>Text</option>
                <option value='video'>Video</option>
              </select>
            </div> */}
             <div className="col-xl-3 col-lg-4 col-sm-12 mb-3">
               <h6 className="edit-box-label ml-2 mb-2">Lesson Number</h6>
               <input
                type="text"
                className="form-control"
                placeholder="Lesson Number"
                value={lessonSubIndex}
                onInput={(e) => setLessonSubIndex(e.target.value)}
              />
             </div>
          </div>


          {contentType === 'video' ? (
            <div className="col-xl-5 col-lg-8 col-sm-12 mb-3">
              <h6 className="edit-box-label ml-2 mb-2">Lesson Content</h6>
              <div
                className={`file-dragger video ${videoPreview ? 'preview' : ''}`}
                onClick={openFileSelect}
              >
                {videoPreview ? (
                  <video width="100%" controls style={{ objectFit: 'cover' }}>
                    <source src={videoPreview} />
                  </video>
                ) : (
                  <h6>Upload Video</h6>
                )}
              </div>
              <input
                id="upload-video"
                type="file"
                accept="video/*"
                autoComplete="off"
                tabIndex={-1}
                style={{ display: "none" }}
                onChange={(e) => handleSelectVideo(e)}
              />
              {!isUploading && (
                <div className="row mt-2">
                  <button
                    className="mx-auto mr-3"
                    onClick={clearVideo}
                    style={{ borderRadius: '8px', color: 'white', background: 'red', paddingLeft: '5px', paddingRight: '5px' }}
                  >Delete</button>
                </div>
              )}
            </div>
          ) : (
            <div className="row m-3">
              <h6 className="edit-box-label ml-2">Lesson Content</h6>
              <div className="col-xl-12 editor-container">
                <ReactQuill
                  forwardedRef={editorRef}
                  className="editor"
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={content}
                  onChange={handleEditorChange}
                  style={{ minHeight: 350 }}
                />
              </div>
            </div>
          )}

          {isUploading ? (
            <div className="row my-4 mt-2">
              <div className="m-auto spinner-border text-info"></div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 d-flex justify-content-end" style={{ marginTop: '80px' }}>
                <button onClick={addLesson} className="main-btns">Save</button>
                <button onClick={() => history.goBack()} className="main-btns">Cancel</button>
              </div>
            </div>
          )}

          {error ? (
            <div className="alert alert-danger mt-3">
              {error}
            </div>
          ) : null}

          {success ? (
            <div className="alert alert-success mt-3">
              {success}
            </div>
          ) : null}
        </div>
      </div>

    </>
  );
}