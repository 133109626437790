import React, { useState, useEffect , useCallback } from "react";
import CourseCard from "../components/CourseCard";
import blockchain from "../assets/images/blockchain.jpg";
import nft from "../assets/images/nft.jpeg";
import web3 from "../assets/images/web3.jpg";
import cover from "../assets/images/cover.jpg";
import {TbPlus} from "react-icons/tb";
import {Link} from "react-router-dom";
import { url } from '../actions/config'
import { debounce } from "lodash"
import axios from 'axios'



export default function Courses() {

  const [courses, setCourses] = useState([])
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([])
  const [error, setError] = useState('')
  const [displayedCourses, setDisplayedCourses] = useState([]);
  const [coursesPerPage, setCoursesPerPage] = useState(10);
  const [loading , setIsLoading] = useState(false);


  const getCourses = (searched_course) => {
    setIsLoading(true)
    let filter = {};
    if(!searched_course || searched_course === ""){
      filter = {displaySearch : ""}
    } else {
      filter = {displaySearch : searched_course}
    }
    axios.post(url() + 'api/admin/courses/list', filter).then(response => {
      if (response?.status == 200) {
        setCourses(response?.data?.data)
        setDisplayedCourses(response?.data?.data.slice(0, coursesPerPage));
        setIsLoading(false)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
      setIsLoading(false)
    })
  }


  function searchChange(text) {
    setSearch(text);
    if (text === "") {
      getCourses("");
    }
  }

  const searchQuery = useCallback(debounce(() => {
    getCourses(search)
  }, 400), [search]);

  useEffect(() => {
    searchQuery();
    // Cancel the debounce on useEffect cleanup.
    return searchQuery.cancel;
  }, [search, searchQuery]);


  const getCategories = () => {
    axios.post(url() + 'api/admin/categories', {}).then(response => {
      if (response?.status == 200) {
        console.log('categories', response?.data?.data);
        setCategories(response?.data?.data || [])
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  useEffect(() => {
    getCourses()
    // getCategories()
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });


const handleScroll = () => {
  const bottom =
    Math.ceil(window.innerHeight + window.scrollY) >=
    document.documentElement.scrollHeight;
  if (bottom) {
    setTimeout(() => {
      setDisplayedCourses((prevCourses) =>
        [...prevCourses, ...courses.slice(coursesPerPage, coursesPerPage + 10)]
      );
      setCoursesPerPage((prevCoursesPerPage) => prevCoursesPerPage + 10);
    }, 100); // Add a delay of 100ms before adding the new courses
  }
};

  return (
    <div className="course-view">
      <div className="row mt-2  justify-content-end">
        <div className="col-xl-8  col-md-8 col-12 mb-2">
          <Link className="btn btn-primary mr-2 mb-1" to="/courses/add">
            <TbPlus color="white" />
            <span>Add Course</span>
          </Link>
          <Link className="btn btn-primary mb-1" to="/categories">
            <span>Manage Categories</span>
          </Link>
        </div>
        <div className="col-xl-4  col-md-4 col-sm-6 col-12 mb-2 ">
          <input type="text" value={search} onChange={(e) => searchChange(e.target.value)} className='form-control fm_bg p-1' placeholder="Search Courses..."/>
        </div>
      </div>
      <div className="clearfix"> </div>

      <div className="row course-container">
        {loading && (
                  <div className="row my-4 mt-2">
                  <div className="m-auto spinner-border text-info"></div>
                </div>
              )}

        {!loading && !displayedCourses?.length ? (
          <div className="row">
            <p className="display_text">There are no courses.</p>
          </div>
        ) : (
          displayedCourses.map((data, index) => (
            <div
              // className="card-demo"
              className="col-xl-4 col-lg-6 col-md-12 mt-3 mb-3"
              key={index}
            >
              <CourseCard
                courseId={data?._id}
                courseIndex = {index}
                image={data?.banner_image}
                title={data?.name}
                category={data?.category_id?.name}
                // text={data.description.length > 150 ? data.description.slice(0,150).concat(".....") : data.description}
                text={data?.description}
                numLesson={data?.lessons_count}
                isActive={data?.status}
                purchased={data?.purchased}
              />
            </div>
          ))
        )}
      </div>
      </div>
  );
}
