import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom'
import { url } from '../actions/config'
import axios from 'axios'


export default function ManageCategories() {
  const [categories, setCategories] = useState([]);
  const [newName, setNewName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState({});
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const history = useHistory()

  const getCategories = () => {
    axios.post(url() + 'api/admin/categories', {}).then(response => {
      if (response?.status == 200) {
        console.log('categories', response?.data?.data);
        setCategories(response?.data?.data || [])
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  useEffect(() => {
    getCategories()
  }, [])

  const addCategory = () => {
    setError('')

    if (!newName?.trim()) {
      setError('Please enter a name for the category.')
      return;
    }

    let params = {
      name: newName
    }

    axios.post(url() + 'api/admin/category', params).then(response => {
      if (response?.status == 200) {
        setSuccess('Category successfully added.')
        getCategories()
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const updateCategory = () => {
    setError('')

    if (!newName?.trim()) {
      setError('Please enter a name for the category.')
      return;
    }

    let params = {
      name: newName
    }

    axios.put(url() + 'api/admin/category/' + selectedCategory._id, params).then(response => {
      if (response?.status == 200) {
        setSuccess('Category successfully updated.')
        getCategories()
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const deleteCategory = () => {
    setError('')

    axios.delete(url() + 'api/admin/category/' + selectedCategory._id).then(response => {
      if (response?.status == 200) {
        setSuccess('Category successfully deleted.')
        getCategories()
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }


  return (
    <div className="add-course-view bg-light">
      <h4>Manage Categories</h4>
      <table className="table">
        <thead>
          <th>Category Name</th>
          <th>Actions</th>
        </thead>
        <tbody>
          {categories.map(category => {
            return (
              <tr key={category._id}>
                <td>{category.name}</td>
                <td>
                  <div>
                    <button
                      className="btn btn-sm btn-primary mr-1"
                      data-toggle="modal"
                      data-target="#edit-category"
                      onClick={() => { setSelectedCategory(category); setNewName(category.name);}}
                    >Edit</button>
                    <button
                      className="btn btn-sm btn-primary mr-1"
                      data-toggle="modal"
                      data-target="#delete-category"
                      onClick={() => { setSelectedCategory(category); setNewName(category.name);}}
                    >Delete</button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="d-flex justify-content-center mb-3">
        <button
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#new-category"
          onClick={() => setNewName('')}
        >New Category</button>
      </div>

      <div className="row">
        <div className="col-12">

          <div className="modal fade" id="new-category">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title mb-0">New Category</h5>
                </div>
                <div className="modal-body bg-light">
                  <h6 className="ml-2 mb-2 mt-2">Category Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={newName}
                    onInput={(e) => setNewName(e.target.value)}
                    onChange={() => {}}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-light"
                    data-dismiss="modal"
                    data-target="#new-category"
                  >Cancel</button>
                  <button
                    className="btn btn-primary primary"
                    data-dismiss="modal"
                    data-target="#new-category"
                    onClick={() => addCategory()}
                  >Done</button>
                </div>
              </div>
            </div>
          </div>


          <div className="modal fade" id="edit-category">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title mb-0">Edit Category</h5>
                </div>
                <div className="modal-body bg-light">
                  <h6 className="ml-2 mb-2 mt-2">Category Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={newName}
                    onInput={(e) => setNewName(e.target.value)}
                    onChange={() => {}}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-light"
                    data-dismiss="modal"
                    data-target="#new-category"
                    onClick={() => setSelectedCategory({})}
                  >Cancel</button>
                  <button
                    className="btn btn-primary primary"
                    data-dismiss="modal"
                    data-target="#new-category"
                    onClick={() => updateCategory()}
                  >Done</button>
                </div>
              </div>
            </div>
          </div>


          <div className="modal fade" id="delete-category">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title mb-0">Delete Category</h5>
                </div>
                <div className="modal-body bg-light">
                  <p>Are you sure you want to delete {selectedCategory.name}?</p>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-light"
                    data-dismiss="modal"
                    data-target="#new-category"
                    onClick={() => setSelectedCategory({})}
                  >Cancel</button>
                  <button
                    className="btn btn-primary primary"
                    data-dismiss="modal"
                    data-target="#new-category"
                    onClick={() => deleteCategory()}
                  >Delete</button>
                </div>
              </div>
            </div>
          </div>



          {error ? (
            <div className="alert alert-danger">
              {error}
            </div>
          ) : <></>}

          {success ? (
            <div className="alert alert-success">
              {success}
            </div>
          ) : <></>}
        </div>
      </div>
    </div>
  );
}
