import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { FaGripLines } from "react-icons/fa";



export default function LessonCard({ index, title, course_id, lesson_id, dragging }) {

  return (
    <div
      className={`lesson-card bg-light`}
      draggable="true"
    >
      <b><span className="text-danger mr-2">{index}.</span>{title}</b>
      <Link to={`/course/${course_id}/update-lesson/${lesson_id}`}
        className="ml-auto mr-2"
      >
        <button>
          <FiEdit className="icon" />
          <span>Edit</span>
        </button>
      </Link>
      <div className="px-2 py-1" role="button">
        <FaGripLines />
      </div>
    </div>
  );
}
