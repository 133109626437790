import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom'
import { url } from '../actions/config'
import { BiArrowBack } from "react-icons/bi";
import axios from 'axios'
import Modal from "react-bootstrap/Modal";



export default function AddCourse() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState([]);
  const [courseId , setCourseId] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);
  const [numLessons, setNumLessons] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [deleteModal , setDeleteModal] = useState(false);

  const { id } = useParams()
  const history = useHistory()

  const deleteCourse = () => {
    setDeleteModal(false)
    axios.delete(url() + 'api/admin/courses/' + id).then(response => {
      if (response?.status == 200) {
      setSuccess('Course successfully Deleted')
        window.setTimeout(() => {
          history.push('/courses')
        }, 1000)

      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const getCourseDetails = () => {
    axios.get(url() + 'api/admin/courses/' + id).then(response => {
      if (response?.status == 200) {
        // console.log('courses', response?.data?.data);
        const course = response?.data?.data?.[0]
        // console.log(course);
        setCourseId(course?._id)
        setName(course?.name)
        setDescription(course?.description)
        setCategory(course?.category_id?._id)
        setImage(course?.banner_image)
        setImagePreview(url() + course?.banner_image)
        setNumLessons(course?.lessons_count)
        setStatus(course?.status)
        // if (response?.data?.total) setTotal(response?.data?.total)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const getCategories = () => {
    axios.post(url() + 'api/admin/categories', {}).then(response => {
      if (response?.status == 200) {
        // console.log('categories', response?.data?.data);
        setCategories(response?.data?.data || [])
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  useEffect(() => {
    getCategories()
    if (id) {
      getCourseDetails()
    }
  }, [])

  useEffect(() => {
    // console.log(category);
  }, [category])

  const addCourse = () => {
    setError('')

    if (!name?.trim()) {
      setError('Please enter a name for the course.')
      return;
    }
    // if (name?.length > 50) {
    //   setError('Course name must be 50 characters or less.')
    //   return;
    // }
    if (!description?.trim()) {
      setError('Please enter a description for the course.')
      return;
    }
    if (description?.length > 1000) {
      setError('Description must be 1000 characters or less.')
      return;
    }
    if (!category) {
      setError('Please select a category for the course.')
      return;
    }
    if (!image) {
      setError('Please select a banner image for the course.')
      return;
    }

    let formData = new FormData()
    formData.append('name', name)
    formData.append('description', description)
    formData.append('category_id', category)
    formData.append('banner_image', image)

    axios.post(url() + 'api/admin/courses/', formData).then(response => {
      if (response?.status == 200) {
        setSuccess('Course successfully added.')
        window.setTimeout(() => {
          history.push('/courses')
        }, 1000)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }

  const updateCourse = () => {
    setError('')

    if (!name?.trim()) {
      setError('Please enter a name for the course.')
      return;
    }
    // if (name?.length > 50) {
    //   setError('Course name must be 50 characters or less.')
    //   return;
    // }
    if (!description?.trim()) {
      setError('Please enter a description for the course.')
      return;
    }
    if (description?.length > 1000) {
      setError('Description must be 1000 characters or less.')
      return;
    }
    if (!category) {
      setError('Please select a category for the course.')
      return;
    }
    if (!image) {
      setError('Please select a banner image for the course.')
      return;
    }
    if (status == undefined) {
      setError('Please select a status for the course.')
      return;
    }

    let formData = new FormData()
    formData.append('name', name)
    formData.append('description', description)
    formData.append('category_id', category)
    formData.append('banner_image', image)
    formData.append('status', status)

    axios.put(url() + 'api/admin/courses/' + id, formData).then(response => {
      if (response?.status == 200) {
        setSuccess('Course successfully updated.')
        window.setTimeout(() => {
          history.push('/courses')
        }, 1000)
      }
    }).catch(error => {
      console.error(error);
      setError(error?.response?.data?.msg)
    })
  }


  const handleSelectImage = (e) => {
    const file = e.target.files[0]
    if (!file) return;
    setImage(file);
    let src = URL.createObjectURL(file);
    setImagePreview(src);
  }

  const openFileSelect = () => {
    const fileSelect = document.getElementById("upload-image");
    fileSelect.click()
  }

  const handleClose = () => {
    setDeleteModal(false)
  }


  return (
    <div className="add-course-view bg-light">
       <button
            className="position-absolute d-flex align-items-center mt-2 btn btn-secondary"
            onClick={() => history.goBack()}
          ><BiArrowBack className="back-arrow" /></button>
      <h4>{id ? 'Update' : 'Add'} Course</h4>
      <div className="row">
        <div className="col-12">
          <div className="row mb-4">
            <div className="col-12">
              <h6 className="ml-2 mb-2 mt-2">Course Title</h6>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                value={name}
                onInput={(e) => setName(e.target.value)}
                maxLength={40}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12 mb-2">
              <label htmlFor="banner-image" className="w-100">
                <div className="file-dragger" onClick={openFileSelect}>
                  {imagePreview ? (
                    <img src={imagePreview} width="100%" height="100%" style={{ objectFit: 'cover' }} />
                  ) : (
                    <h6>Upload Banner Image</h6>
                  )}
                </div>
              </label>
              <input
                id="upload-image"
                type="file"
                accept="image/png,image/jpg,image/jpeg"
                autoComplete="off"
                tabIndex={-1}
                style={{display: "none"}}
                onChange={handleSelectImage}
              />
            </div>
            <div className="col-12 form-group">
              <label className="mb-2 ml-2">Course Description</label>
              <textarea
                rows="5"
                className="form-control"
                placeholder="Course Description"
                value={description}
                onInput={(e) => setDescription(e.target.value)}
                onChange={() => {}}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12">
              <h6 className="ml-2 mb-2 mt-2">Category</h6>
              <select value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value=''></option>
                {categories.map(c => {
                  return (
                    <option value={c._id} key={c._id}>{c.name}</option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className="row">
            {id ? (
              <div className="col-12 mb-3">
                <h6 className="ml-2 mb-2 mt-2">Status</h6>
                <select value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </div>
            ) : <></>}
          </div>

          {error ? (
            <div className="alert alert-danger">
              {error}
            </div>
          ) : <></>}

          {success ? (
            <div className="alert alert-success">
              {success}
            </div>
          ) : <></>}
        </div>

        <div className="col-12 p-3">
          <button className="add-course-button" onClick={id ? updateCourse : addCourse}>Save</button>
          {id && (
            <button className="add-course-button" onClick={() => setDeleteModal(true)}>Delete</button>
          )}
        </div>
      </div>

      {/* DELETE COURSE MODAL VIEW */}
      <Modal show={deleteModal}  aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
        <Modal.Header className="modal-header">
          <Modal.Title id="contained-modal-title-vcenter">Course Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <p>
               Are you sure you want to delete {name}? Please note that some users may have already started this course. If the course is deleted, they will no longer have access to it.
          </p>
           </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-danger font-weight-bold mt-3" onClick={deleteCourse}>
               Delete
            </button>
            <button className="btn btn-danger font-weight-bold mt-3" onClick={handleClose}>
               Close
            </button>
        </Modal.Footer>
      </Modal>
    </div>

    
  );
}
